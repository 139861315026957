<div class="status">
  <div [class.grey]="!data.open.status" class="round"></div>
  <div [class.grey]="!data.in_progress.status" class="line"></div>
  <div [class.grey]="!data.in_progress.status" class="round"></div>
  <div [class.grey]="!data.resolved.status && !data.rejected.status" class="line"></div>
  <div [class.grey]="!data.resolved.status && !data.rejected.status" class="round"></div>
</div>
<div class="status second-line">
  <div [class.grey]="!data.open.status" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>check</mat-icon>
    <div fxLayout="column">
      <h3>Ingediend</h3>
      <div *ngIf="data.open.date" class="date">{{ data.open.date | date:'dd-MM-YY' }}</div>
    </div>
  </div>
  <div [class.grey]="!data.in_progress.status" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>check</mat-icon>
    <div fxLayout="column">
      <h3>In behandeling</h3>
      <div *ngIf="data.in_progress.date" class="date">{{ data.in_progress.date | date:'dd-MM-YY' }}</div>
    </div>
  </div>
  <div [class.grey]="!data.resolved.status && !data.rejected.status" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>check</mat-icon>
    <div fxLayout="column">
      <h3 *ngIf="data.rejected.status">Niet opgelost</h3>
      <h3 *ngIf="!data.rejected.status">Afgehandeld</h3>
      <div class="date">
        <ng-container *ngIf="data.rejected.date">{{ data.rejected.date | date:'dd-MM-YY' }}</ng-container>
        <ng-container *ngIf="data.resolved.date">{{ data.resolved.date | date:'dd-MM-YY' }}</ng-container>
      </div>
    </div>
  </div>
</div>
