import { Component, Input, OnInit } from '@angular/core';
import { Report } from '../../../app/interfaces/report/report';
import { ReportStatuses } from '../../../app/interfaces/base/report-statuses';

@Component({
  selector: 'fury-report-progress',
  templateUrl: './report-progress.component.html',
  styleUrls: ['./report-progress.component.scss']
})
export class ReportProgressComponent implements OnInit {

  @Input() report: Report;

  public data: ReportStatuses = {
    open: {
      status: false,
      date: null,
    },
    in_progress: {
      status: false,
      date: null,
    },
    rejected: {
      status: false,
      date: null,
    },
    resolved: {
      status: false,
      date: null,
    },
  };

  constructor() {
  }

  ngOnInit(): void {
    if (this.report) {
      this.data.open.status = true;
      if (typeof this.report.received_at !== 'undefined' && this.report.received_at !== null) {
        this.data.open.date = this.report.received_at;
      }

      if (typeof this.report.handling !== 'undefined') {
        if (typeof this.report.handling.assigned_at !== 'undefined') {
          if (this.report.handling.assigned_at !== null) {
            this.data.in_progress.status = true;
            this.data.in_progress.date = this.report.handling.assigned_at;
          }
        }
      }

      if (this.report.status_id === 5) {
        this.data.in_progress.status = true;

        this.data.resolved.status = true;
        this.data.resolved.date = this.report.handling.closed_at;
      }

      if (this.report.status_id === 6) {
        this.data.in_progress.status = true;

        this.data.rejected.status = true;
        this.data.rejected.date = this.report.handling.closed_at;
      }
    }
  }
}
