import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class ApiEndpointsService {

  private endpoints: { key: string, route: string }[] | [] = [
    {key: 'reports.track-and-trace', route: 'v1/reports/track-and-trace/:id'},
    {key: 'reports.download-attachment', route: 'v1/reports/:id/attachments/:attachment_id'},
  ];

  constructor() {
  }

  get(name: string, params?): string | null {
    const index: number = _.findIndex(this.endpoints, d => d.key === name);

    if (index !== -1) {
      if (params) {
        const url: string = this.endpoints[index].route;

        return url.replace(/(:[a-zA-Z_]+)/g, (m: string) => {
          return params[m];
        });
      } else {
        return this.endpoints[index].route;
      }
    }

    throw new Error('Unknown route URL requested: ' + name);
  }
}
