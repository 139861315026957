<fury-page-layout>
  <fury-page-layout-content @fadeInUp>
    <fury-container>
      <fury-page-layout-content-width wide="middle">
        <ng-container *ngIf="!report && !isLoading">
          <fury-alert-box class="warn">
            Er is geen melding gevonden.
          </fury-alert-box>
        </ng-container>
        <ng-container *ngIf="report">
          <h1>Volgen van uw melding</h1>
          <fury-report-progress [report]="report"></fury-report-progress>
          <h3>Uw melding</h3>
          <div class="row">
            <div class="title">Rapportnummer:</div>
            <div class="content">{{ report.number }}</div>
          </div>
          <div class="row">
            <div class="title">Type overlast:</div>
            <div class="content">{{ getHeadCategory() }}</div>
          </div>
          <ng-container *ngIf="report.report_type_id === 1">
            <div class="row">
              <div class="title">Soort overlast:</div>
              <div class="content">{{ getMainCategory() }}</div>
            </div>
            <div class="row">
              <div class="title">Specifieke overlast:</div>
              <div class="content">{{ getSubCategory() }}</div>
            </div>
          </ng-container>
          <div class="row">
            <div class="title">Toelichting overlast:</div>
            <div class="content">{{ report.description }}</div>
          </div>
          <ng-container *ngIf="report.report_type_id === 1">
            <div class="row">
              <div class="title">Datum en tijd waarneming:</div>
              <div class="content">{{ report.received_at | date:'dd-MM-yy HH:mm' }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="report.report_type_id === 2">
            <div class="row">
              <div class="title">Wanneer vinden de voorgenomen werkzaamheden plaats:</div>
              <div class="content">
                <ng-container *ngIf="report.complaint?.noticed_at">
                  {{ report.complaint.noticed_at | date:'dd-MM-yy HH:mm' }}
                </ng-container>
                <ng-container *ngIf="report.complaint?.end_at">
                  tot einde {{ report.complaint.end_at | date:'dd-MM-yy HH:mm' }}
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="report.report_type_id === 1">
            <div class="row">
              <div class="title">Type veroorzaker</div>
              <div class="content">{{ report.suspect.type }}</div>
            </div>
            <div class="row" *ngIf="report.suspect.type !== 'Onbekend'">
              <div class="title">Vermoedelijke veroorzaker:</div>
              <div class="content">{{ report.suspect.name }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="report.report_type_id === 1">
            <div class="row photos" *ngIf="report.attachments.total">
              <div class="title">Foto's of besanden van uw melding:</div>
              <div class="content">
                <div fxLayout="column" fxLayoutGap="16px">
                  <div *ngIf="report.attachments.media.length" class="media" fxLayout="row"
                       fxLayoutGap="16px">
                    <div *ngFor="let photo of report.attachments.media; let i = index">
                      <img *ngIf="photo.type === 'image'" [lightbox]="i" [src]="photo.src"
                           alt="">
                      <video *ngIf="photo.type === 'video'" [lightbox]="i"
                             [src]="photo.src"></video>
                    </div>
                  </div>
                  <div *ngIf="report.attachments.documents.length" class="documents"
                       fxLayout="column">
                    <ng-container *ngFor="let document of report.attachments.documents">
                      <div (click)="downloadAttachment(report.id, document.id)" *ngIf="document.type === 'document'"
                           fxFlex
                           fxLayoutAlign="space-between">
                        {{ document.original_filename }}
                        <mat-icon>download</mat-icon>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <h3>Locatie van overlast</h3>
          <div class="row">
            <div class="title">Locatie:</div>
            <div class="content">
              <ng-container *ngIf="!report.complaint?.location_unknown">
                <ng-container *ngIf="report.surrounding_id !== 2">
                  <ng-container *ngIf="report.complaint.location !== ''">
                    {{ report.complaint.location }}
                    <span class="link">
                    (<a (click)="viewLocation({lat: report.complaint.lat, lng: report.complaint.lng})">bekijk op de kaart</a>)
                  </span>
                  </ng-container>
                  <ng-container *ngIf="report.complaint.location === ''">-</ng-container>
                </ng-container>
                <ng-container *ngIf="report.surrounding_id === 2">
                  <ng-container *ngIf="report.complaint.lat !== null">
                    Lat: {{ report.complaint.lat }}, long: {{ report.complaint.lng }}
                    <span class="link">
                    (<a (click)="viewLocation({lat: report.complaint.lat, lng: report.complaint.lng})">bekijk op de kaart</a>)
                  </span>
                  </ng-container>
                  <ng-container *ngIf="report.complaint.lat === null">-</ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="report.complaint.location_unknown">
                Onbekend locatie
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="report.report_type_id === 2">
            <h3>Bedrijfsgegevens</h3>
            <div class="row">
              <div class="title">Naam:</div>
              <div class="content">{{ report.suspect.name }}</div>
            </div>
            <div class="row">
              <div class="title">Adres:</div>
              <div class="content">
                {{ report.suspect.location }}
                <span class="link">
                    (<a
                    (click)="viewLocation({lat: report.suspect.lat, lng: report.suspect.lng})">bekijk op de kaart</a>)
                  </span>
              </div>
            </div>
          </ng-container>

          <h3 *ngIf="report.report_type_id === 1">Contact gegevens</h3>
          <h3 *ngIf="report.report_type_id === 2">Contactpersoon</h3>
          <ng-container *ngIf="report.reporter.anonymous">
            <div class="row">
              <div class="title">Anoniem</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!report.reporter.anonymous">
            <div class="row">
              <div class="title">Aanhef:</div>
              <div class="content">
                {{ report.reporter.prefix }}
              </div>
            </div>
            <div class="row">
              <div class="title">Voorletters:</div>
              <div class="content">
                {{ report.reporter.initials }}
              </div>
            </div>
            <div class="row">
              <div class="title">Tussenvoegsel en achternaam:</div>
              <div class="content">
                {{ report.reporter.middlename }} {{ report.reporter.lastname }}
              </div>
            </div>
            <ng-container *ngIf="report.report_type_id === 1">
              <div class="row">
                <div class="title">Woonadres:</div>
                <div class="content" *ngIf="!report.complaint.same_as_reporter_address">
                  {{ report.reporter.location }}
                </div>
                <div class="content" *ngIf="report.complaint.same_as_reporter_address">Locatie van melding is gelijk aan
                  woonadres
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="title">E-mail:</div>
              <div class="content">{{ report.reporter.email ?? '-' }}</div>
            </div>
            <div class="row">
              <div class="title">Telefoonnummer:</div>
              <div class="content">{{ report.reporter.phone ?? '-' }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="report.handling.notes && !report.handling.skip_notes">
            <h3>Melding voltooid notitie</h3>
            <div [innerHTML]="report.handling.notes"></div>
          </ng-container>
        </ng-container>
        <div class="white-background" *ngIf="isLoading">
          <fury-loading-overlay [isLoading]="true"></fury-loading-overlay>
        </div>
      </fury-page-layout-content-width>
    </fury-container>
  </fury-page-layout-content>
</fury-page-layout>
