import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BackdropModule } from '../../@fury/shared/backdrop/backdrop.module';
import { MaterialModule } from '../../@fury/shared/material-components.module';
import { LayoutComponent } from './layout.component';
import { FurySharedModule } from '../../@fury/fury-shared.module';
import { TrackAndTraceModule } from '../pages/track-and-trace/track-and-trace.module';
import { PageHeaderModule } from '../../@fury/shared/page-header/page-header.module';
import { PageFooterModule } from '../../@fury/shared/page-footer/page-footer.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FurySharedModule,

    // Core
    BackdropModule,
    TrackAndTraceModule,
    PageHeaderModule,
    PageFooterModule,
  ],
  declarations: [LayoutComponent]
})
export class LayoutModule {
}
