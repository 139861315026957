import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import { Observable, throwError } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ServerResponse } from '../../interfaces/base/server.response';
import { catchError, map } from 'rxjs/operators';

@Injectable()

export class ApiService {

  public generalError: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(private http: HttpClient,
              private snackbarService: SnackbarService,
  ) {
  }

  get isOnline(): boolean {
    return !!window.navigator.onLine;
  }

  get(
    url: string,
  ): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.get(environment.api_endpoint + url, {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
    }
  }

  download(url: string, filename: string = null, mimeType: string = 'application/octet-stream'): void {
    if (this.isOnline) {
      this.http.get<Blob>(environment.api_endpoint + url, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
        .subscribe(
          (response: HttpResponse<Blob>) => this.handleFileDownload(response, filename, mimeType),
          (error: HttpErrorResponse) => this.handleErrorResponse(error)
        );
    } else {
      this.notAvailableOffline();
    }
  }

  handleResponse(res: HttpResponse<ServerResponse>): ServerResponse {
    this.generalError.next(false);
    if (typeof res.body !== 'undefined') {
      if (typeof res.body.message !== 'undefined') {
        this.snackbarService.success(res.body.message);
      }

      return res.body;
    }
  }

  handleFileDownload(response: HttpResponse<Blob>, filename: string = null, mimeType: string = 'application/octet-stream'): void {
    const header: string = response.headers.get('content-disposition'),
      responseFilename: string = header.split(';')[1].trim().split('=')[1].replace(/"/g, '');
    FileSaver.saveAs(
      new Blob([response.body], {type: mimeType}),
      (filename !== null ? filename : (responseFilename !== null ? responseFilename : null))
    );
  }

  handleErrorResponse(error: HttpErrorResponse): Observable<ServerResponse> {
    this.generalError.next(true);

    if (typeof error.error !== 'undefined' && typeof error.error.message !== 'undefined') {
      this.snackbarService.error(error.error.message);
    } else {
      this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
    }

    return throwError(error.error);
  }

  notAvailableOffline(): void {
    this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
  }
}
