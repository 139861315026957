import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[furyPageLayoutContentWidth],fury-page-layout-content-width',
  host: {
    class: 'fury-page-layout-content-width'
  }
})
export class PageLayoutContentWidthDirective {

  @Input() wide: 'narrow' | 'middle' | 'full' = 'full';

  constructor() {
  }

  @HostBinding('class.fury-page-layout-content-narrow')
  get isNarrow() {
    return this.wide === 'narrow';
  }

  @HostBinding('class.fury-page-layout-content-middle')
  get isMiddle() {
    return this.wide === 'middle';
  }

  @HostBinding('class.fury-page-layout-content-full')
  get isFull() {
    return this.wide === 'full';
  }

}
