import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';

@Injectable()

export class TrackAndTraceService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  get(id: string): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('reports.track-and-trace', {':id': id}));
  }

  downloadAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-attachment', {':id': id, ':attachment_id': attachment_id}));
  }
}
