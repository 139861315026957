<div mat-dialog-title>
  Bekijk melding locatie
</div>
<div mat-dialog-content>
  <div (leafletMapReady)="onMapReady($event)" [leafletOptions]="mapOptions" class="map"
       id="map"
       leaflet></div>
  <leaflet-loading-control
      [map]='mapContainer'
      [options]="loadingOptions"
  ></leaflet-loading-control>
</div>
<mat-dialog-actions>
  <button [mat-dialog-close]="false" color="primary" mat-button>SLUITEN</button>
</mat-dialog-actions>
