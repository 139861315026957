import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../@fury/shared/scrollbar/scrollbar.module';
import { TrackAndTraceComponent } from './track-and-trace.component';
import { LoadingOverlayModule } from '../../../@fury/shared/loading-overlay/loading-overlay.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FuryAlertBoxModule } from '../../../@fury/shared/alert-box/alert-box.module';
import { LightboxModule } from 'ng-gallery/lightbox';
import { RouterModule } from '@angular/router';
import { FurySharedModule } from '../../../@fury/fury-shared.module';
import { ReportProgressModule } from '../../../@fury/shared/report-progress/report-progress.module';
import { TrackAndTraceRoutingModule } from './track-and-trace-routing.module';
import { TrackAndTraceService } from '../../services/reports/track-and-trace.service';

@NgModule({
  imports: [
    CommonModule,
    TrackAndTraceRoutingModule,
    MaterialModule,
    ScrollbarModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    FuryAlertBoxModule,
    LightboxModule,
    RouterModule,
    FurySharedModule,
    ReportProgressModule,
  ],
  declarations: [TrackAndTraceComponent],
  exports: [TrackAndTraceComponent],
  providers: [
    TrackAndTraceService,
  ]
})

export class TrackAndTraceModule {
}
