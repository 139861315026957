import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AppUpdateDialogComponent } from './app-update-dialog/app-update-dialog.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxLoadingControlModule } from '@runette/ngx-leaflet-loading';
import { LocationViewerDialogComponent } from './location-viewer/location-viewer-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    LeafletModule,
    NgxLoadingControlModule,
    FlexLayoutModule,
    ScrollbarModule
  ],
  declarations: [
    AppUpdateDialogComponent,
    LocationViewerDialogComponent,
  ],
  exports: [
    AppUpdateDialogComponent,
    LocationViewerDialogComponent,
  ]
})

export class DialogsModule {
}
