import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'fury-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  protected readonly environment = environment;

  constructor() {
  }
}

