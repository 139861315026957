import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DivIcon, latLng, LayerGroup, Map, MapOptions, Marker, MarkerOptions, tileLayer } from 'leaflet';

@Component({
  selector: 'fury-location-viewer-dialog',
  templateUrl: './location-viewer-dialog.component.html',
  styleUrls: ['./location-viewer-dialog.component.scss'],
})

export class LocationViewerDialogComponent implements AfterViewInit {

  public mapOptions: MapOptions = {
    layers: [
      tileLayer('https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png', {
        detectRetina: true,
        attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
        minZoom: 6,
        maxZoom: 19,
      })
    ],
    zoom: 11,
    center: latLng(51.80, 4.94)
  };
  public loadingOptions: any = {
    position: 'topleft',
  };

  public mapContainer: Map = null;
  private markerLayer: LayerGroup = new LayerGroup();
  private marker: Marker = null;
  private markerOptions: MarkerOptions = {
    icon: new DivIcon({
      html: '<div class="leaflet-custom-marker-pin"></div>',
      iconSize: [24, 36],
      iconAnchor: [12, 36],
    }),
    draggable: false,
  };
  private defaultZoomed: number = 13;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  ) {
  }

  ngAfterViewInit(): void {
    if (typeof this.data.lat !== 'undefined' && typeof this.data.lng !== 'undefined' && this.data.lat !== null && this.data.lng !== null) {
      this.updateMarker([this.data.lat, this.data.lng]);
    }
  }

  onMapReady(mapContainer: Map): void {
    this.mapContainer = mapContainer;
    this.mapContainer.addLayer(this.markerLayer);
  }

  updateMarker(coords: [number, number]): void {
    if (this.marker) {
      this.marker.setLatLng({lat: coords[0], lng: coords[1]});
    } else {
      this.marker = new Marker({lat: coords[0], lng: coords[1]}, this.markerOptions);
      this.markerLayer.addLayer(this.marker);
    }

    this.mapContainer.setView(coords, this.defaultZoomed);
  }
}
