import { Component, OnDestroy, OnInit } from '@angular/core';
import { Report } from '../../interfaces/report/report';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { TrackAndTraceService } from '../../services/reports/track-and-trace.service';
import { Gallery, GalleryItem, ImageItem, VideoItem } from 'ng-gallery';
import { takeUntil } from 'rxjs/operators';
import { ReportAttachment } from '../../interfaces/report/report-attachment';
import { MatDialog } from '@angular/material/dialog';
import { LocationViewerDialogComponent } from '../../../@fury/shared/dialog/location-viewer/location-viewer-dialog.component';
import { fadeInUpAnimation } from '../../../@fury/animations/fade-in-up.animation';

@Component({
  selector: 'fury-track-and-trace',
  templateUrl: './track-and-trace.component.html',
  styleUrls: ['./track-and-trace.component.scss'],
  animations: [fadeInUpAnimation],
})
export class TrackAndTraceComponent implements OnInit, OnDestroy {

  public isLoading: boolean = null;
  public report: Report = null;
  public galleryMedia: GalleryItem[];

  private destroy$: Subject<null> = new Subject<null>();

  constructor(private activatedRoute: ActivatedRoute,
              public trackAndTraceService: TrackAndTraceService,
              public gallery: Gallery,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    document.documentElement.style.setProperty('--primary-color', '#1b143c');
    document.documentElement.style.setProperty('--primary-color-text', '#ffffff');


    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params): void => {
      if (typeof params['id'] !== 'undefined') {
        if (params['id'] !== null) {
          this.isLoading = true;

          this.getData(params['id']);
        }
      }
    });
  }

  getData(reportUniqueId: string): void {
    this.trackAndTraceService.get(reportUniqueId).subscribe(
      (response: ServerResponse): void => {
        if (typeof response.data !== 'undefined') {
          this.report = response.data;

          if (this.report.organisation !== null && typeof this.report.organisation.color_primary_action !== 'undefined' && this.report.organisation.color_primary_action !== '') {
            document.documentElement.style.setProperty('--primary-color', this.report.organisation.color_primary_action);
            document.documentElement.style.setProperty('--primary-color-text', this.report.organisation.color_primary_action_text);
          }

          if (this.report.attachments.total && this.report.attachments.media.length) {
            this.galleryMedia = this.report.attachments.media.map((item: ReportAttachment): VideoItem | ImageItem => {
              if (item.type === 'video') {
                return new VideoItem({
                  src: <any>[
                    {
                      url: item.src,
                      type: item.mime,
                    }
                  ],
                  thumb: item.thumb,
                });
              } else {
                return new ImageItem({
                  src: item.src,
                  thumb: item.thumb,
                });
              }
            });
            this.gallery.ref().load(this.galleryMedia);
          }

        }

        this.isLoading = false;
      },
      (): void => {
        this.isLoading = false;
      }
    );
  }

  getHeadCategory(): string {
    if (this.report) {
      if (typeof this.report.categories !== 'undefined') {
        if (typeof this.report.categories[0] !== 'undefined') {
          if (typeof this.report.categories[0].head_category !== 'undefined') {
            return this.report.categories[0].head_category;
          }
        }
      }
    }
  }

  getMainCategory(): string {
    if (this.report) {
      if (typeof this.report.categories !== 'undefined') {
        if (typeof this.report.categories[0] !== 'undefined') {
          if (typeof this.report.categories[0].main_category !== 'undefined') {
            return this.report.categories[0].main_category;
          }
        }
      }
    }
  }

  getSubCategory(): string {
    if (this.report) {
      if (typeof this.report.categories !== 'undefined') {
        if (typeof this.report.categories[0] !== 'undefined') {
          if (typeof this.report.categories[0].sub_category !== 'undefined') {
            return this.report.categories[0].sub_category;
          }
        }
      }
    }
  }

  viewLocation(coords: { lat: number, lng: number }): void {
    this.dialog.open(LocationViewerDialogComponent, {
      data: coords
    });
  }

  downloadAttachment(reportId: number, attachmentId: number): void {
    this.trackAndTraceService.downloadAttachment(reportId, attachmentId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.isLoading = false;
  }
}
