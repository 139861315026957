import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageFooterComponent } from './page-footer.component';
import { FurySharedModule } from '../../fury-shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FurySharedModule,
  ],
  exports: [
    PageFooterComponent
  ],
  declarations: [PageFooterComponent]
})
export class PageFooterModule {
}
