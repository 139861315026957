export const environment = {
  production: true,
  appVersionAddon: '',
  webappName: 'Milieu Klachten Centrale',
  api_endpoint: 'https://api.milieuklachtencentrale.nl/',
  homepage_url: 'https://www.milieuklachtencentrale.nl/',
  webform_url: 'https://meldformulier.milieuklachtencentrale.nl/',
  latitude: 51.55,
  longitude: 5.083333,
  sentry: {
    dsn: 'https://491aff7610b0ce036fd2f8c58e8f5406@o4507735602298880.ingest.de.sentry.io/4507748308680784'
  }
};
